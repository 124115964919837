<template>
	<div>
		  <div  class="text item" style="padding: 30px 0;background-color: #fff;">
			  <div>
				   <el-form ref="form" :model="memberInfo"
					label-width="120px">
					<el-form-item label="">
					  <div class="flex">
					  	<el-input v-model="phone" placeholder="请输入会员手机号" style="width: 200px;"></el-input>
					  	<el-button @click="searchMember" type="primary" size="mini">搜索</el-button>
					  </div>
					</el-form-item>
					   <el-form-item label="会员名称">
					     <el-input style="width: 300px;" v-model="memberInfo.uname" disabled></el-input>
					   </el-form-item>
					   <el-form-item label="当前积分">
					     <el-input style="width: 300px;" v-model="memberInfo.score" disabled></el-input>
					   </el-form-item>
					   <el-form-item label="积分调整为">
					     <el-input-number :min="0" 
						 v-model="memberInfo.num" ></el-input-number>
					   </el-form-item>
					   <el-form-item label="">
					     <el-button @click="confirm" type="primary" size="mini">保存</el-button>
					   </el-form-item>
				   </el-form>	   
				   
			  </div>
		  </div>
		
	</div>
</template>

<script>
	export default {
		data(){
			return {
				phone:"",
				memberInfo:{
					uname:"",
					phone:"",
					score:"",
					type:1,
					num:0,
					mid:0,
					handle_person:'管理员',
				}
			}
		},
		mounted(){
		    document.title='积分调整'
		},
		methods:{
			confirm(){
				if(confirm()){
					if(!this.memberInfo.mid) return this.$message.error('请搜索会员')
					this.$post('/pc/member/setMemberScore',this.memberInfo,res=>{
						let result = res.data
						if(result.code==200){
							this.memberInfo.num = 0
							this.$message.success('修改成功')
							this.searchMember()
						}
					})
				}
			},
			searchMember(){
				if(!this.phone) return this.$message.error('请输入会员手机号')
				this.$post('/pc/member/getMemberScore',{phone:this.phone},res=>{
					let result = res.data
					if(result.code==200){
						this.memberInfo.uname = result.data.uname
						this.memberInfo.phone = result.data.phone
						this.memberInfo.score = result.data.score
						this.memberInfo.mid = result.data.mid
						
					}
				})
				
			},
		}
	}
</script>

<style>
</style>